import axios from 'axios'

export const getCategoriesAsync = (params) => axios.get('/categroy/findCategroy', { 
    params: params 
});

export const login = data => axios.post('/user/login', data);
export const loginDoctor = data => axios.post('/doctor/login', data);
export const addDoctor = data => axios.post('/doctor/add', data);
export const monitorupdate = data => axios.post('/user/updateMonitor', data);

//注册获取验证码
export const getRegisterCode = params => axios.get('/user/getRegisterCode', { params });
//登录获取验证码
export const getLoginCode = params => axios.get('/user/getLoginCode',  { params });



//忘记密码获取验证码
export const getForgetCode = params => axios.get('/user/getForgetCode',  { params });
//验证码登录
export const loginCode = data => axios.post('/user/loginCode', data);
//验证码登录
export const register = data => axios.post('/user/register', data);
//验证码登录

export const forgetPassword = data => axios.post('/user//update/password', data);
// export const monitorupdate = data => axios.post('/user/updateMonitor', data);
//查询是否含有这个医院
export const selectMeetingNameList = (params) => axios.get('/user/selectMeetingNameList', { 
    params: params 
});


export const monitorAdd = data => axios.post('/monitor/add', data);
export const userUpdate = data => axios.post('/user/update', data);




