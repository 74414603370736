import React from "react";
import { Avatar } from 'antd';

class SmallVideoComponent extends React.Component{
    constructor(){
        super();
        this.state = {
       displayMainVideo:true
            };
       	
       }
        handleshow() {
               this.setState({
                   displayMainVideo:true
               })
           }
           handlehide() {
               this.setState({
                   displayMainVideo:false
               })
           }
       
    
    componentDidMount = () => {
        const {stream} = this.props;
        this.video.srcObject = stream;
    }
    
    componentWillUnmount = () => {
        this.video.srcObject = null;
    }

    handleClick = () => {
        // let {id,index} = this.props;
        // this.props.onClick({id,index});
    }

    render = () => {
        const { id,stream,name,muted } = this.props;
        return (
            <div>
            	{
                muted?
            	<div onClick={this.handleClick} className='small-video-div' style={{display:"none"}}>
            	    <video
            	        ref={ref =>{
            	            this.video = ref;
            	        }}
            	        id={id}
            	        autoPlay
            	        playsInline
            	       controls="controls"
            	        className="small-video-size"
            	    />
            	    <div className='small-video-id-div'>
            	        <a className='small-video-id-a'>{name}</a>
            	    </div>
            	</div>
                : <div onClick={this.handleClick} className='small-video-div'>
                    <video
                        ref={ref =>{
                            this.video = ref;
                        }}
                        id={id}
                        autoPlay
                        playsInline
                       controls="controls"
                        className="small-video-size"
                    />
                   
                    <div className='small-video-id-div'>
                        <a className='small-video-id-a'>{name}</a>
                    </div>
                </div>
            	}
            </div>
        )
    }
}

export default SmallVideoComponent;