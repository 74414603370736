import 'babel-polyfill'
import React from "react";
import { HashRouter as Router,Route,} from 'react-router-dom';

import Samples from './Samples';
import RoomList from './RoomList';
import UserLogin from './UserLogin';

import VideoPlayer   from './VideoPlayer';

import Register from './Register'; // 确保路径正确
import Login from './Login'; // 确保路径正确
import ForgetPassword from './ForgetPassword'; 

//主组件
class App extends React.Component {

  render() {
    //路由配置
    return <Router>
           
                {/* 首页 <Route exact path="/" component={UserLogin} />  <Route exact path="/" component={Streamer} /> */}
				<Route exact path="/" component={Login} />
				<Route exact path="/VideoPlayer" component={VideoPlayer} />
				
				<Route exact path="/RoomList" component={RoomList} />
                <Route exact path="/Samples" component={Samples} />
                <Route exact path="/Register" component={Register} />
                <Route exact path="/UserLogin" component={UserLogin} />
                <Route exact path="/Login" component={Login} />
                <Route exact path="/ForgetPassword" component={ForgetPassword} />
                
              
			  
          
        </Router>
  }
}
//导出主组件 
export default App;


